import React from "react";

// import tab1 from 'assets/images/tabs/tab9.jpg';

const ProjectTabSection9 = () => {
  return (
    <>
      <div className="tab-wrapper">
        <div className="img-wrapper">
          <img
            data-src="https://cdn-prod.heartfulness.org/FBH/Coimbatore/ukkadam/image1.jpeg"
            className="lazyload"
            alt="tab1"
          />
        </div>
      </div>
    </>
  );
};

export default ProjectTabSection9;
