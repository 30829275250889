import React from "react";

import Carousel from "react-multi-carousel";

import { truncate } from "lodash";

import treeLeftIconGreen from "assets/images/icons/tree-leaf-left-green.svg";

import treeRightIconGreen from "assets/images/icons/tree-leaf-right-green.svg";

import { pressReleaseData } from "assets/data/coimbatore/press-release";

import "react-multi-carousel/lib/styles.css";

export default function PressReleases() {
  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 768,
      },
      items: 2,
    },
    mobile: {
      breakpoint: {
        max: 767,
        min: 0,
      },
      items: 1,
    },
  };

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div className="carousel-button-group">
        <div className="navigations">
          <button onClick={() => previous()}>
            <img src={treeLeftIconGreen} alt="treeLeftIconGreen" />
          </button>
          <button onClick={() => next()}>
            <img src={treeRightIconGreen} alt="treeRightIconGreen" />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="press-releases-section">
      <div className="section pb-0">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h3 className="text-start">
                  Press <span className="green">Releases</span>
                </h3>
              </div>
              <div className="carousel-section">
                <Carousel
                  arrows={false}
                  autoPlaySpeed={3000}
                  draggable
                  infinite
                  keyBoardControl
                  minimumTouchDrag={80}
                  partialVisible
                  renderButtonGroupOutside={true}
                  swipeable
                  responsive={responsive}
                  customButtonGroup={<ButtonGroup />}
                >
                  {pressReleaseData.map((item) => {
                    return (
                      <div className="press-release-wrapper" key={item.id}>
                        <img
                          data-src={item.img}
                          className="lazyload"
                          alt={item.alt}
                        />
                        <h3>
                          {truncate(item.title, {
                            length: 80,
                            omission: "...",
                          })}
                        </h3>
                        <p>
                          {truncate(item.desc, {
                            length: 140,
                            omission: "...",
                          })}
                        </p>
                        <div className="text-end">
                          <span className="read-more">
                            <a
                              href={item.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span>Read More</span>
                              <i className="icon icon-arrow-right-short"></i>
                            </a>
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
