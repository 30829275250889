import Rotary from "assets/images/coimbatore/partner/1.png";
import Skal from "assets/images/coimbatore/partner/2.png";
import corporation from "assets/images/coimbatore/partner/3.png";
import aeon from "assets/images/coimbatore/partner/4.png";
import hearty from "assets/images/coimbatore/partner/5.png";

export const partnerCarousel = [
  {
    id: 1,
    img: Rotary,
    alt: "",
    class: "Coimbatore Rotary",
  },
  {
    id: 2,
    img: Skal,
    alt: "",
  },
  {
    id: 3,
    img: corporation,
    alt: "",
  },

  {
    id: 4,
    img: aeon,
    alt: "",
  },
  {
    id: 5,
    img: hearty,
    alt: "",
    class: "partner-logo-items",
  },
];
