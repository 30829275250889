export const ukkadamgalleryImg = [
  {
    id: 1,
    img: "https://cdn-prod.heartfulness.org/FBH/Coimbatore/ukkadam/image1.jpeg",
    alt: "Ukkadam Periyakulam",
  },
  {
    id: 2,
    img: "https://cdn-prod.heartfulness.org/FBH/Coimbatore/ukkadam/image2.jpeg",
    alt: "Ukkadam Periyakulam",
  },
  {
    id: 3,
    img: "https://cdn-prod.heartfulness.org/FBH/Coimbatore/ukkadam/image3.jpeg",
    alt: "Ukkadam Periyakulam",
  },
  {
    id: 4,
    img: "https://cdn-prod.heartfulness.org/FBH/Coimbatore/ukkadam/image4.jpeg",
    alt: "Ukkadam Periyakulam",
  },
  {
    id: 5,
    img: "https://cdn-prod.heartfulness.org/FBH/Coimbatore/ukkadam/image5.jpeg",
    alt: "Ukkadam Periyakulam",
  },
  {
    id: 6,
    img: "https://cdn-prod.heartfulness.org/FBH/Coimbatore/ukkadam/image6.jpeg",
    alt: "Ukkadam Periyakulam",
  },
];
