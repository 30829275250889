import React, { useState, useEffect } from "react";
import DonationService from "services/donations/donation.service";

import config from "assets/config";
import axios from "axios";

export default function Count() {
  const [donorsCount, setDonorsCount] = useState(config.donorsCount);
  const [saplingsCount, setSaplingsCount] = useState(config.saplingsCount);
  const [plantedCount, setPlantedCount] = useState(config.saplingsCount);

  useEffect(async () => {
    try {
      let defaultDonorCount = config.donorsCount;
      let defaultSaplingCount = config.saplingsCount;
      let defaultPlantedCount = config.saplingsCount;
      const url = config.defaultCountApi;
      await axios
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          defaultDonorCount = 1;
          defaultSaplingCount = 3635;
          defaultPlantedCount = 3335;
        });

      const ds = new DonationService();

      const dsResponse = await ds.getDonorSaplingCount("coimbatore_donation");

      if (dsResponse && dsResponse.data) {
        if (dsResponse.data.donorTotalCount !== "") {
          if (
            typeof defaultDonorCount !== "undefined" &&
            defaultDonorCount !== ""
          ) {
            setDonorsCount(
              parseInt(dsResponse.data.donorTotalCount) +
                parseInt(defaultDonorCount)
            );
          } else {
            setDonorsCount(
              parseInt(dsResponse.data.donorTotalCount) +
                parseInt(config.donorsCount)
            );
          }
        }
        if (dsResponse.data.saplingTotalCount !== "") {
          if (
            typeof defaultSaplingCount !== "undefined" &&
            defaultSaplingCount !== ""
          ) {
            setSaplingsCount(
              parseInt(dsResponse.data.saplingTotalCount) +
                parseInt(defaultSaplingCount)
            );
            setPlantedCount(parseInt(defaultPlantedCount));
          } else {
            setSaplingsCount(
              parseInt(dsResponse.data.saplingTotalCount) +
                parseInt(config.saplingsCount)
            );
            setPlantedCount(parseInt(config.saplingsCount));
          }
        }
      }
    } catch (err) {}
  }, []);

  return (
    <div className="count-section">
      <div className="container" style={{ display: "block" }}>
        <div className="row count-section-row reach-count-row">
          <div className="col-md-4 count-section-heading">
            <div className="col-md-12 count-section-heading">
              <h3>Trees Planted So Far</h3>
            </div>
            <div className="col-md-12">
              <div className="count-box">
                <h4>{plantedCount.toLocaleString()}</h4>
              </div>
            </div>
          </div>
          <div className="col-md-4 count-section-heading">
            <div className="col-md-12 count-section-heading">
              <h3>Current Tree Contribution</h3>
            </div>
            <div className="count-box-wrapper">
              <div className="count-box">
                <h4>{saplingsCount.toLocaleString()}</h4>
              </div>
            </div>
          </div>
          <div className="col-md-4 count-section-heading">
            <div className="col-md-12 count-section-heading">
              <h3>Number of Donors</h3>
            </div>
            <div className="count-box-wrapper">
              <div className="count-box">
                <h4>{donorsCount.toLocaleString()}</h4>
              </div>
            </div>
          </div>
          <div className="col-md-6 count-section-heading">
            <div
              className="col-md-12 count-section-heading"
              style={{ textAlign: "left" }}
            >
              <h2> Upcoming plantation</h2>
              <h3 style={{ fontSize: "17px" }}>Date : 8th and 16th August</h3>
              <h3 style={{ fontSize: "17px" }}>
                {" "}
                Venue : Nachipalayam village{" "}
              </h3>
              <h3 style={{ fontSize: "17px" }}> No of trees : 5000 </h3>
            </div>
          </div>
        </div>

        {/*  <div className='row count-section-row'>
          <div className='col-md-12 count-section-heading'>
            <h3>Trees Contributed till Now</h3>
          </div>
          <div className='count-box-wrapper'>
            <div className='count-box'>
              <h4>{saplingsCount.toLocaleString()}</h4>
              <p>Saplings</p>
            </div>
            <div className='count-box'>
              <h4>{donorsCount.toLocaleString()}</h4>
              <p>Donors</p>
            </div>
          </div>
        </div>
        */}
      </div>
    </div>
  );
}
