import React, { useState, useRef, useEffect } from "react";

import { Accordion } from "react-bootstrap";

import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

// import ProjectTabSection1 from "components/home/project/ProjectTabSection1";
// import ProjectTabSection2 from "components/home/project/ProjectTabSection2";
// import ProjectTabSection3 from "components/home/project/ProjectTabSection3";
import ProjectTabSection4 from "components/coimbatore/project/ProjectTabSection4";
// import ProjectTabSection5 from "components/home/project/ProjectTabSection5";
// import ProjectTabSection6 from "components/home/project/ProjectTabSection6";
import ProjectTabSection8 from "components/coimbatore/project/ProjectTabSection8";
import ProjectTabSection9 from "components/coimbatore/project/ProjectTabSection9";

// data

// import { nurseries } from "assets/data/project";

export default function Projects() {
  const [tabName, setTabName] = useState("tab4");

  const stateCarousel = useRef(null);

  const [mapCurrentTab, setMapCurrentTab] = useState(1);

  const [isMobile, setIsMobile] = useState(false);

  const changeTabName = (name) => {
    setTabName(name);
  };

  const responsive = {
    desktop: {
      breakpoint: {
        max: 30000,
        min: 320,
      },
      items: 1,
    },
  };

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div className="carousel-button-group">
        <div className="navigations">
          <button onClick={() => previous()} className="me-3">
            <i className="icon icon-tree-leaf-left"></i>
          </button>
          <button onClick={() => next()}>
            <i className="icon icon-tree-leaf-right"></i>
          </button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    window.innerWidth < 975 ? setIsMobile(true) : setIsMobile(false);
  }, []);

  return (
    <div className="project-section">
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h3 className="text-start">
                  Selected <span className="green">Projects</span>
                </h3>
              </div>
              <div className="accordian-tab-section">
                <div className="row">
                  <div className="aco-tab-section">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header
                          onClick={() => {
                            changeTabName("tab4");
                          }}
                        >
                          5th of June, Periyakulam
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>
                            On the 5th of June 2023, during the World
                            Environment day, we signed an agreement with
                            Coimbatore Corporation , partnering with them to
                            plant trees in Periyakulam lake first and
                            subsequently across all 8 lakes in the city.
                          </p>
                          <p>
                            {" "}
                            The Corporation Commissioner Mr. M. Prathap, IAS
                            inaugurated the tree plantation. Over 200 trees were
                            planted on the same day. Work is in progress to
                            plant about 4000 trees in this location.
                          </p>
                          {isMobile ? <ProjectTabSection4 /> : <></>}
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="2">
                        <Accordion.Header
                          onClick={() => {
                            changeTabName("tab7");
                          }}
                        >
                          May 2023, Geedee Public School, Eachanari
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>
                            Students of Geedee Public school planted about 1200
                            trees in the premises partnering with Forest by
                            heartfulness. Each student was given a tag paired
                            with two trees , in order for them to identify their
                            trees and nurture them till they left the school.
                            This idea was encouraged and the drive supported
                            fully by the School Management.
                          </p>
                        </Accordion.Body>
                        {isMobile ? <ProjectTabSection9 /> : <></>}
                      </Accordion.Item>

                      <Accordion.Item eventKey="3">
                        <Accordion.Header
                          onClick={() => {
                            changeTabName("tab8");
                          }}
                        >
                          2nd & 3rd of July, at Ukkadam Periyakulam
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>
                            {" "}
                            The plantation drive on the 2nd of July inspired the
                            youth further to plant 605 palm seeds in a single
                            day by 5 volunteers at Ukkadam lake bunds.
                          </p>
                          <p>
                            23 students of Government College of Arts and
                            Science Coimbatore have actively participated along
                            with our volunteers of Forest by Heartfulness. They
                            have planted about 530 palm seeds at the backside
                            bund of Periyakulam, Ukkadam.
                          </p>
                        </Accordion.Body>
                        {isMobile ? <ProjectTabSection8 /> : <></>}
                      </Accordion.Item>
                    </Accordion>
                  </div>
                  {!isMobile ? (
                    <div className="aco-tab-content-section">
                      <div>
                        {(() => {
                          switch (tabName) {
                            // case "tab1":
                            //   return <ProjectTabSection1 />;
                            // case "tab2":
                            //   return <ProjectTabSection2 />;
                            // case "tab3":
                            //   return (
                            //     <ProjectTabSection3
                            //       stateCarousel={stateCarousel}
                            //       mapCurrentTab={mapCurrentTab}
                            //     />
                            //   );
                            case "tab4":
                              return <ProjectTabSection4 />;
                            // case "tab5":
                            //   return <ProjectTabSection5 />;
                            // case "tab6":
                            //   return <ProjectTabSection6 />;
                            case "tab7":
                              return <ProjectTabSection9 />;
                            case "tab8":
                              return <ProjectTabSection8 />;
                            default:
                              return <></>;
                          }
                        })()}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
