export const boschgalleryImg = [
  {
    id: 1,
    img: "https://cdn-prod.heartfulness.org/FBH/Coimbatore/greedee-public-schools/image1.jpg",
    alt: "Geedee Public school",
  },
  {
    id: 2,
    img: "https://cdn-prod.heartfulness.org/FBH/Coimbatore/greedee-public-schools/image2.jpg",
    alt: "Geedee Public school",
  },
  {
    id: 3,
    img: "https://cdn-prod.heartfulness.org/FBH/Coimbatore/greedee-public-schools/image3.jpg",
    alt: "Geedee Public school",
  },
  {
    id: 4,
    img: "https://cdn-prod.heartfulness.org/FBH/Coimbatore/greedee-public-schools/image4.jpg",
    alt: "Geedee Public school",
  },
  {
    id: 5,
    img: "https://cdn-prod.heartfulness.org/FBH/Coimbatore/greedee-public-schools/image5.jpg",
    alt: "Geedee Public school",
  },
  {
    id: 6,
    img: "https://cdn-prod.heartfulness.org/FBH/Coimbatore/greedee-public-schools/image6.jpg",
    alt: "Geedee Public school",
  },
  {
    id: 7,
    img: "https://cdn-prod.heartfulness.org/FBH/Coimbatore/greedee-public-schools/image7.jpg",
    alt: "Geedee Public school",
  },
  {
    id: 8,
    img: "https://cdn-prod.heartfulness.org/FBH/Coimbatore/greedee-public-schools/image8.jpg",
    alt: "Geedee Public school",
  },
  {
    id: 9,
    img: "https://cdn-prod.heartfulness.org/FBH/Coimbatore/greedee-public-schools/image9.jpg",
    alt: "Geedee Public school",
  },
];
