import React, { useState, useEffect } from "react";

import Carousel from "react-multi-carousel";

import { partnerCarousel } from "assets/data/coimbatore/partnerCarousel";

import { slice } from "lodash";

import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 5,
    partialVisibilityGutter: 0,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 1,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464,
    },
    items: 2,
    partialVisibilityGutter: 0,
  },
};

export default function PartnerCarousel() {
  const [galleryImages2, setGalleryImages] = useState([]);

  useEffect(() => {
    setGalleryImages(slice(partnerCarousel, 0, 22));
  }, []);

  return (
    <div className="recent-plantation-section-partner bosch-recent-plant hyd-recent-plant-planter">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section pad-photography">
              <div className="section-title section-title-planter">
                <h3 className="text-start">
                  Our <span className="green">Supporting Partners</span>
                </h3>
              </div>
              <div className="carousel-section carousel_padding_bottom">
                <Carousel
                  arrows={true}
                  autoPlaySpeed={2000}
                  autoPlay
                  draggable
                  infinite
                  keyBoardControl
                  minimumTouchDrag={80}
                  partialVisible
                  renderButtonGroupOutside={true}
                  swipeable
                  responsive={responsive}
                >
                  {galleryImages2.map((item) => {
                    return (
                      <div
                        className="partner-logo-wrapper"
                        key={item.id}
                        style={{ width: "100%" }}
                      >
                        <div className={item.class}>
                          <img
                            style={{ width: "100%" }}
                            data-src={item.img}
                            className="lazyload partner-logo"
                            alt={item.alt}
                          />
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
