import React from "react";

const ProjectTabSection4 = () => {
  return (
    <>
      <div className="tab-wrapper">
        <div className="img-wrapper">
          <img
            data-src="https://cdn-prod.heartfulness.org/FBH/Coimbatore/periyakuman/image1.jpg"
            className="lazyload"
            alt="tab1"
          />
        </div>
      </div>
    </>
  );
};

export default ProjectTabSection4;
