import apn from "assets/images/press-release/apn.png";
import th from "assets/images/press-release/the-hindu.png";
import newindian from "assets/images/press-release/newindian-logo.png";

export const pressReleaseData = [
  {
    id: 1,
    img: th,
    alt: "bosch",
    title:
      "Bosch to raise a mini-forest across Coimbatore, Bengaluru and Hyderabad",
    desc: "COIMBATORE Bosch Global Software Technologies (BGSW), formerly known as Robert Bosch Engineering and Business Solutions (RBEI), has partnered with Forests by Heartfulness to plant 25,000 saplings in a mini-forest plantation drive across Coimbatore, Bengaluru and Hyderabad..",
    link: "https://www.thehindu.com/news/cities/Coimbatore/bosch-to-raise-a-mini-forest-across-coimbatore-bengaluru-and-hyderabad/article65193231.ece",
  },

  {
    id: 2,
    img: newindian,
    alt: "union agriculture",
    title:
      "Union Agriculture Minister Narendra Tomar lauds tree culture lab at Kanha",
    desc: "Visiting the Tissue Culture Lab at Kanha Shanti Vanam in Rangareddy district, Tomar said he realized that endangered tree species could be saved and developed besides creating employment..",
    link: "https://www.newindianexpress.com/states/telangana/2021/dec/30/tomar-lauds-tree-culture-lab-at-kanha-2401143.html",
  },
  {
    id: 3,
    img: apn,
    alt: "forest urban",
    title: "Forest by Heartfulness Partners with Zscaler on Urban Forest India",
    desc: "Forest by Heartfulness is partnering with Zscaler™, the leader in cloud security, to plant 15,000 trees in an urban forest project. The plantation drive will benefit local communities in India where Zscaler has operations, including Hyderabad, Bangalore, Pune and Mohali..",
    link: "https://www.apnnews.com/forest-by-heartfulness-partners-with-zscaler-on-urban-forest-india/",
  },
];
