import React, { useState, useEffect } from "react";

import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

import intro1 from "assets/images/intro/intro1.jpg";
import intro2 from "assets/images/intro/intro2.jpg";
import intro3 from "assets/images/intro/intro3.jpg";
import intro4 from "assets/images/intro/intro4.jpg";
import intro5 from "assets/images/intro/intro5.jpg";

export default function Intro() {
  const responsive = {
    desktop: {
      breakpoint: {
        max: 30000,
        min: 320,
      },
      items: 1,
    },
  };

  const [isMobile, setIsMobile] = useState(false);
  const [mobileViewContent, setMobileViewContent] = useState(false);
  const [showReadMore, setShowReadMore] = useState(true);
  const [showReadLess, setShowReadLess] = useState(false);

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div className="carousel-button-group">
        <div className="navigations">
          <button onClick={() => previous()} className="me-3">
            <i className="icon icon-tree-leaf-left"></i>
          </button>
          <button onClick={() => next()}>
            <i className="icon icon-tree-leaf-right"></i>
          </button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
  }, []);

  useEffect(() => {
    if (mobileViewContent === true) {
      setShowReadMore(false);
    }
  }, [mobileViewContent]);

  return (
    <div className="intro-section">
      <div className="section pt-0">
        <div className="container">
          <div className="row intro-section-row">
            <div className={showReadMore === false ? "col-md-6" : "col-md-6"}>
              <div className="section-title">
                <h3 className="text-start">
                  <span className="green">Forests</span> <br /> by Heartfulness
                </h3>
                <h4>Introduction</h4>
              </div>
              <div className="para-section">
                <p
                  style={{
                    marginBottom: isMobile === true ? "0px" : "25px",
                  }}
                >
                  Our primary goal is to plant at least 30 million native and
                  endemic trees across India by 2025.
                </p>

                <p>
                  FBH Coimbatore aims to plant 20 lakh trees by 2025 in support
                  of this mission.
                </p>

                <p
                  style={{
                    marginBottom: isMobile === true ? "0px" : "25px",
                  }}
                >
                  To achieve this goal, Heartfulness Institute’s forestry,
                  agro-forestry, and climate experts are helping to develop
                  nurseries in 18 cities across the country, with a goal of
                  having 28 active nationwide nurseries by 2023.
                </p>
                {!isMobile && (
                  <p
                    style={{
                      marginBottom: showReadMore === false ? "25px" : "0px",
                      marginTop: !isMobile === true ? "25px" : "0px",
                    }}
                  >
                    To achieve this, a team of forestry, agroforestry, and
                    climate experts will guide the project for the next five
                    years. As a first step, nurseries are being developed in 18
                    cities across the country. Saplings of carefully selected
                    tree and plant species are raised in these nurseries during
                    the non-plantation season every year between October and
                    May. Mass plantation of these saplings takes place every
                    year between June and September (monsoon) and January and
                    February (late winter). Heartfulness volunteers, farmers,
                    NGOs, school children, and the general public will carry out
                    this initiative. The planted saplings will be effectively
                    monitored and nurtured by providing optimum conditions for
                    growth to ensure a high survival rate. <br />
                    In 2019, FBH conducted a successful mass plantation drive in
                    64 cities, planting 64,000 trees in one day through its
                    volunteers and partnering organizations.
                  </p>
                )}
                {showReadMore && (
                  <span
                    className="special-donation-read-more"
                    onClick={() => {
                      setMobileViewContent(true);
                      setShowReadLess(true);
                    }}
                    style={{ marginBottom: "20px" }}
                  >
                    Read More...
                  </span>
                )}
                {mobileViewContent && (
                  <>
                    {isMobile && (
                      <p
                        style={{
                          marginTop: showReadMore === false ? "25px" : "0px",
                        }}
                      >
                        FBH
                      </p>
                    )}

                    {showReadLess && !isMobile && (
                      <span
                        className="special-donation-read-more"
                        onClick={() => {
                          setMobileViewContent(false);
                          setShowReadMore(true);
                          setShowReadLess(false);
                        }}
                      >
                        Read Less...
                      </span>
                    )}
                  </>
                )}
                <p
                  style={{
                    marginTop: "20px",
                  }}
                >
                  We are continuously grateful for your support!
                </p>
              </div>
            </div>

            {!isMobile && (
              <div
                className="col-md-6"
                // style={{ marginTop: isMobile === true ? "20px" : "0px" }}
                style={{
                  marginBottom: isMobile === true ? "0px" : "25px",
                }}
              >
                <div className="intro-carousel">
                  <Carousel
                    arrows={false}
                    autoPlaySpeed={3000}
                    draggable
                    infinite
                    keyBoardControl
                    minimumTouchDrag={80}
                    partialVisible
                    renderButtonGroupOutside={true}
                    swipeable
                    responsive={responsive}
                    customButtonGroup={<ButtonGroup />}
                  >
                    <div className="img-wrapper">
                      <img
                        data-src={intro1}
                        className="lazyload"
                        alt="Daaji-planting-a-sapling"
                      />
                    </div>
                    <div className="img-wrapper">
                      <img
                        data-src={intro2}
                        className="lazyload"
                        alt="forest-by-heartfulness"
                      />
                    </div>
                    <div className="img-wrapper">
                      <img
                        data-src={intro3}
                        className="lazyload"
                        alt="Children-with-placards"
                      />
                    </div>
                    <div className="img-wrapper">
                      <img
                        data-src={intro4}
                        className="lazyload"
                        alt="Little-boy-praying-with-sapling"
                      />
                    </div>
                    <div className="img-wrapper">
                      <img
                        data-src={intro5}
                        className="lazyload"
                        alt="Planters-meditating"
                      />
                    </div>
                  </Carousel>
                </div>
              </div>
            )}

            {showReadLess && isMobile && (
              <span
                className="special-donation-read-more"
                onClick={() => {
                  setMobileViewContent(false);
                  setShowReadMore(true);
                  setShowReadLess(false);
                }}
              >
                Read Less...
              </span>
            )}
            <div
              className="col-md-12"
              style={{ marginTop: isMobile ? "10px" : "" }}
            >
              <div className="para-section">
                <p>
                  Please note that online donations are subject to a 2%
                  processing fee from the payment gateway / credit card company.
                  So donors of large amounts are encouraged to mail a cheque or
                  do a NEFT/RTGS transfer along with this{" "}
                  <a
                    href="https://cdn-prod.heartfulness.org/FBH/HFI-Donation-form.docx"
                    target="blank"
                  >
                    form
                  </a>{" "}
                  to
                  <a
                    href="mailto:accounts@heartfulnessinstitute.org"
                    className="anchor-tags"
                  >
                    {" "}
                    accounts@heartfulnessinstitute.org{" "}
                  </a>
                  .{" "}
                  <span
                    style={{
                      fontFamily: "Avenir LT Std",
                      fontSize: "16px",
                      lineHeight: "0px",
                      color: "#595959",
                      marginBottom: "45px",
                    }}
                  >
                    <b>All donations will be tax deductible</b>
                  </span>
                  .
                </p>

                <div className="col-md-12" style={{ marginTop: "25px" }}>
                  <div className="row">
                    <div className="col-md-6">
                      <h5 class="special-donation-sub-heading">
                        Postal address for mailing cheques is :{" "}
                      </h5>
                      <p className="paragraph-tags">Heartfulness Institute,</p>
                      <p className="paragraph-tags">
                        13/110 Kanha Shanti Vanam,
                      </p>
                      <p className="paragraph-tags">
                        Kanha Village, Nandigama Mandal,
                      </p>
                      <p className="paragraph-tags">Ranga Reddy District,</p>
                      <p className="paragraph-tags">Hyderabad – 500018</p>
                      <p className="paragraph-tags">Telangana, India.</p>
                    </div>
                    <div className="col-md-6">
                      <h5 class="special-donation-sub-heading">
                        Required details for NEFT/RTGS transfers :{" "}
                      </h5>
                      <p className="paragraph-tags">
                        Account Name: Heartfulness Institute
                      </p>
                      <p className="paragraph-tags">
                        Account Number: 50338713007
                      </p>
                      <p className="paragraph-tags">IFSC Code: IDIB000C565</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12" style={{ marginTop: "20px" }}>
                  <div className="container msg-info-wrapper msg-info-wrapper-container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="msg-info">
                          <div
                            className="para-section"
                            style={{ padding: "10px" }}
                          >
                            <p
                              style={{
                                textAlign: !isMobile ? "center" : "",
                                fontSize: isMobile ? "15px" : "18px",
                                marginBottom: "10px",
                              }}
                            >
                              <b>
                                In case of any queries or concerns regarding
                                large donations, please contact:
                              </b>
                              <br />
                              <b>Email </b> : {/* <br /> */}
                              <a
                                href="mailto:fbh@heartfulness.org"
                                className="anchor-tags"
                              >
                                {"   "}
                                fbh@heartfulness.org{" "}
                              </a>{" "}
                              <br />
                              <b>Contact </b>: 92458 00568, 96555 00011, 93620
                              77666
                            </p>
                          </div>
                          <p
                            style={{
                              textAlign: "center",
                              fontSize: "17px",
                            }}
                          >
                            <b>
                              For questions on CSR contributions, information on
                              how to start your own plantation drive, or
                              collaboration queries, please email
                            </b>
                          </p>
                          <div className="text-center">
                            <button className="btn btn-secondary fhb-btn">
                              <a href="mailto:fbh@heartfulness.org">
                                {" "}
                                fbh@heartfulness.org{" "}
                              </a>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
