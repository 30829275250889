export const periyakulamgalleryImg = [
  {
    id: 1,
    img: "https://cdn-prod.heartfulness.org/FBH/Coimbatore/periyakuman/image1.jpg",
    alt: "Periyakulam Lake",
  },
  {
    id: 2,
    img: "https://cdn-prod.heartfulness.org/FBH/Coimbatore/periyakuman/image2.jpg",
    alt: "Periyakulam Lake",
  },
];
