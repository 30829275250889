import React, { useState, useEffect } from "react";

import { SRLWrapper } from "simple-react-lightbox";

import { periyakulamgalleryImg } from "assets/data/coimbatore/Periyagulam-plantationgallery";

import { slice } from "lodash";

import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 3,
    partialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464,
    },
    items: 2,
    partialVisibilityGutter: 30,
  },
};

export default function Periyagulamcarousel() {
  const [galleryImages2, setGalleryImages] = useState([]);

  //  const [key, setKey] = useState('kanha');

  useEffect(() => {
    setGalleryImages(slice(periyakulamgalleryImg, 0, 2));
  }, []);

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div className="carousel-button-group">
        <div className="arrow-navigations custom-arrow-navigation-event">
          <button className="pr-custom" onClick={() => previous()}>
            <i className="icon icon-arrow-left-short"></i>
          </button>
          <button className="pr-rightcustom" onClick={() => next()}>
            <i className="icon icon-arrow-right-short"></i>
          </button>
        </div>
      </div>
    );
  };

  return (
    <SRLWrapper>
      <Carousel
        arrows={false}
        autoPlaySpeed={3000}
        draggable
        infinite
        keyBoardControl
        minimumTouchDrag={80}
        partialVisible
        renderButtonGroupOutside={true}
        swipeable
        responsive={responsive}
        customButtonGroup={<ButtonGroup />}
      >
        {galleryImages2.map((item) => {
          return (
            <div className="img-wrapper" key={item.id}>
              <img data-src={item.img} className="lazyload" alt={item.alt} />
              <div className="overlay">
                <i className="icon icon-arrows-fullscreen"></i>
              </div>
            </div>
          );
        })}
      </Carousel>
    </SRLWrapper>
  );
}
