import React, { useState, useEffect } from "react";

import SEO from "components/seo/index";

import Layout from "../specialdonationlayout/index";

import Banner from "components/special-donation/SpecialDonationBanner";

import Count from "components/coimbatore/Count";
import DonationsGoSection from "components/home/DonationsGoSection";
import Projects from "components/coimbatore/Projects";
import RecentPlantations from "components/coimbatore/recentplantation";
import PartnerCarousel from "components/coimbatore/PartnerCarousel";
import DonationWidgetForm from "forms/CoimbatoreDonationWidgetForm";

import Intro from "components/coimbatore/Intro";

// schema
import { specialDonationSchema } from "forms/validations/specialDonation";

// data
import { amountSelect } from "forms/coimbatoreGiftingData";

import CurrencyService from "services/currency/currency.service";
import SimpleReactLightbox from "simple-react-lightbox";
import PressReleases from "components/coimbatore/PressReleases";
import SpentSection from "components/coimbatore/SpentSection";

import { ceil } from "lodash";

// markup
const CoimbatoreDonation = () => {
  const [amountObj, setAmountObj] = useState([]);
  const [baseAmountValue, setBaseAmountValue] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  const initBaseAmount = async () => {
    const cs = new CurrencyService();
    const csResponse = await cs.getINR();
    csResponse && csResponse.data && csResponse.data.rates;
    const currencyList = csResponse.data.rates;

    const baseAmount = 5000;

    setBaseAmountValue(baseAmount);

    const baseAmountConversion = amountSelect?.map((item) => {
      item.amount.INR = item.amount.INR;
      const rate = 0;

      Object.keys(currencyList).map((currencyType) => {
        const rate = ceil(item.amount.INR / currencyList[currencyType]);
        if (rate > 0) {
          item.amount[currencyType] = rate;
        }
      });
      if (item.type === "input") {
        item.amount.INR = "";
        item.amount.USD = "";
      }
      return item;
    });

    setAmountObj(baseAmountConversion);
    setAmountObj(amountSelect);
  };

  useEffect(() => {
    localStorage.setItem("donationFrom", 1);
    initBaseAmount();
  }, []);

  useEffect(() => {
    window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
  }, []);

  return (
    <>
      <SEO title="Forests By Heartfulness" />
      <Layout isSticky={true} wrapperClass="home">
        <section id="home">
          <Banner />
        </section>
        <section id="count-section">
          <Count />
        </section>
        <section id="introduction">
          <Intro />
        </section>
        <section
          id="special-donation-section"
          style={{ padding: isMobile === false ? "0px 20px" : "" }}
        >
          <DonationWidgetForm
            formType="donation"
            validationSchema={specialDonationSchema}
            amountSelect={amountObj}
            baseAmountValue={baseAmountValue}
          />
        </section>
        <section id="partner-carousel">
          <PartnerCarousel />
        </section>
        <section id="donation-go">
          <DonationsGoSection />
        </section>
        <section id="spent">
          <SpentSection />
        </section>
        <section id="projects">
          <Projects />
        </section>
        <SimpleReactLightbox>
          <section id="recent-plantation">
            <RecentPlantations />
          </section>
        </SimpleReactLightbox>
        <section id="press-releases">
          <PressReleases />
        </section>
      </Layout>
    </>
  );
};

export default CoimbatoreDonation;
