import React, { useState, useEffect } from "react";

import Carousel from "react-multi-carousel";

import { Link } from "gatsby";

import CarouselButtonGroup from "utils/carousel-arrow";

// service
import DonationService from "services/donations/donation.service";

import config from "assets/config";

import { spentSection } from "assets/data/coimbatore/spent-section";

export default function SpentSection() {
  const [plantPrice, setPlantPrice] = useState([]);

  const [isMobile, setIsMobile] = useState(false);

  const responsive = {
    desktop: {
      breakpoint: {
        max: 567,
        min: 320,
      },
      items: 1,
    },
  };

  useEffect(async () => {
    window.innerWidth < 567 ? setIsMobile(true) : setIsMobile(false);

    const ds = new DonationService();
    const responseData = await ds.getDonorSaplingCount("coimbatore_donation");

    if (responseData && responseData.data) {
      if (responseData.data.saplingPrice !== "") {
        setPlantPrice(responseData.data.saplingPrice);
      } else {
        setPlantPrice(config.saplingsPrice);
      }
    }
  }, []);

  return (
    <div className="spent-section donation-go">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <section className="section pt-0">
              <div className="section-title">
                <h3 className="text-start">
                  How is it <span className="green">Spent?</span>
                </h3>
              </div>

              <div className="row spent-section-row">
                {isMobile ? (
                  <Carousel
                    arrows={false}
                    autoPlaySpeed={3000}
                    draggable
                    infinite
                    keyBoardControl
                    minimumTouchDrag={80}
                    renderButtonGroupOutside={true}
                    swipeable
                    ssr={false}
                    responsive={responsive}
                    customButtonGroup={<CarouselButtonGroup />}
                  >
                    {spentSection.map((item) => {
                      return (
                        <div className="col-md-2 col-sm-4" key={item.id}>
                          <div className="img-box-wrapper">
                            <div className="img-box">
                              <img src={item.img} alt={item.alt} />
                            </div>
                            <div className="content-box">
                              <p
                                dangerouslySetInnerHTML={{ __html: item.title }}
                              ></p>
                              <div className="cost">{item.price}</div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Carousel>
                ) : (
                  spentSection.map((item) => {
                    return (
                      <div className="col-md-2 col-sm-4" key={item.id}>
                        <div className="img-box-wrapper">
                          <div className="img-box">
                            <img src={item.img} alt={item.alt} />
                          </div>
                          <div className="content-box">
                            <p
                              dangerouslySetInnerHTML={{ __html: item.title }}
                            ></p>
                            <div className="cost">{item.price}</div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="total-cost-link">
                    <Link to="/">
                      Total cost of Three-year-old tree ₹ {plantPrice}
                    </Link>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
